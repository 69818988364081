<template>
  <page-layout ref="layout" label1="联系" label2="我们" navName="contactus" :navData="navData">
    <router-view class="content" @loading="displayShow"></router-view>
  </page-layout>
</template>
<script>
import PageLayout from '@/components/common/PageLayout'
import { isMobile } from '@/helper/utils'
export default {
  components: {
    PageLayout
  },
  data() {
    return {
      navData: [
        { name: '机构分布', class: 'Organization' },
        { name: '招贤纳士', class: 'Recruit' },
        { name: '明亚自媒体', class: 'Media' }
      ]
    }
  },
  beforeCreate() {
    if (isMobile()) {
      document.querySelector('html').style.fontSize = '71px'
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === '/') {
      var docEI = document.documentElement;
      var clientWidth = docEI.clientWidth
      docEI.style.fontSize = 100 * (clientWidth / 1920) + 'px'
    }
    next();
  },
  methods: {
    displayShow(loading) {
      if (this.$refs['layout']) {
        this.$refs['layout'].displayShow(loading)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
